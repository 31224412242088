<script setup lang="ts">
import BaseButton from '@/components/library/BaseButton.vue'
import BaseIcon from '@/components/library/BaseIcon.vue'
import BaseLottiePlayer from '@/components/library/BaseLottiePlayer.vue'
import { trackEvent } from '@/utils/client/analytics'
import { useWindowSizeStore } from '@/utils/client/store/windowSizeStore'
import type { Tool } from '@/utils/client/vuecomposables/useTools'
import { __, p__ } from '@/utils/shared/intl'
import { debounce } from 'es-toolkit/compat'
import { computed, ref } from 'vue'

const props = defineProps<{
  tools: Tool[]
}>()

const { isSmallerThanTabletLandscape } = useWindowSizeStore()

// Refs to store references to Lottie player instances for each tool
const toolIllustrationRefs = ref<Record<string, InstanceType<typeof BaseLottiePlayer>>>({})

const searchQuery = ref('')

const filteredTools = computed(() => {
  const query = searchQuery.value.toLowerCase().trim()
  if (!query) return props.tools

  return props.tools.filter(
    (tool) => tool.name.toLowerCase().includes(query) || tool.description.toLowerCase().includes(query),
  )
})

const currentHoverState = ref<Record<string, boolean>>({})

function debugLog(message: string, ...args: any[]): void {
  const isDebugLoggingEnabled = false
  if (isDebugLoggingEnabled) {
    console.log(message, ...args)
  }
}

function isAnimationStopped(lottiePlayer: InstanceType<typeof BaseLottiePlayer>): boolean {
  if (!lottiePlayer.lottieWorkerInstance) return true
  if (!lottiePlayer.lottieWorkerInstance.isPlaying) return true
  return (
    lottiePlayer.lottieWorkerInstance?.isStopped ||
    lottiePlayer.lottieWorkerInstance?.isFrozen ||
    lottiePlayer.lottieWorkerInstance?.isPaused
  )
}
/**
 * Handles forward animation of a tool's illustration
 * This is called when a tool is hovered or focused
 * @param toolId - The unique identifier of the tool being animated
 */
const animateIllustrationForward = (toolId: string): void => {
  const lottiePlayer = toolIllustrationRefs.value[toolId]
  if (!lottiePlayer?.lottieWorkerInstance) return

  debugLog('currentState - hovered', {
    isAnimationStopped: isAnimationStopped(lottiePlayer),
    currentFrame: lottiePlayer.lottieWorkerInstance.currentFrame,
    mode: lottiePlayer.lottieWorkerInstance.mode,
  })

  currentHoverState.value[toolId] = true

  // Only start animation if not already playing forward
  if (isAnimationStopped(lottiePlayer)) {
    startForwardAnimation(toolId)
  }
}

/**
 * Handles backward animation of a tool's illustration
 * This is called when a tool loses hover or focus
 * @param toolId - The unique identifier of the tool being animated
 */
const animateIllustrationBackwards = (toolId: string): void => {
  const lottiePlayer = toolIllustrationRefs.value[toolId]
  if (!lottiePlayer?.lottieWorkerInstance) return

  debugLog('currentState - unhovered', {
    isAnimationStopped: isAnimationStopped(lottiePlayer),
    currentFrame: lottiePlayer.lottieWorkerInstance.currentFrame,
    mode: lottiePlayer.lottieWorkerInstance.mode,
  })

  currentHoverState.value[toolId] = false

  if (isAnimationStopped(lottiePlayer)) {
    startBackwardsAnimation(toolId)
  }
}

const startForwardAnimation = (toolId: string): void => {
  const lottiePlayer = toolIllustrationRefs.value[toolId]
  if (!lottiePlayer?.lottieWorkerInstance) return

  const onComplete = () => {
    debugLog('forwards complete')
    lottiePlayer.lottieWorkerInstance?.removeEventListener('complete', onComplete)
    if (!currentHoverState.value[toolId]) {
      debugLog('forwards done but tool is not hovered, starting backwards')
      startBackwardsAnimation(toolId)
    }
  }
  lottiePlayer.lottieWorkerInstance.addEventListener('complete', onComplete)
  lottiePlayer.lottieWorkerInstance.setMode('forward')
  lottiePlayer.lottieWorkerInstance.play()
}

const startBackwardsAnimation = (toolId: string): void => {
  const lottiePlayer = toolIllustrationRefs.value[toolId]
  if (!lottiePlayer?.lottieWorkerInstance) return

  const onComplete = () => {
    debugLog('backwards complete')
    lottiePlayer.lottieWorkerInstance?.removeEventListener('complete', onComplete)
    if (currentHoverState.value[toolId]) {
      debugLog('backwards done but tool is still hovered, starting forwards')
      startForwardAnimation(toolId)
    }
  }
  lottiePlayer.lottieWorkerInstance.addEventListener('complete', onComplete)
  lottiePlayer.lottieWorkerInstance.setMode('reverse')
  lottiePlayer.lottieWorkerInstance.play()
}

const trackToolClick = (toolId: string): void => {
  trackEvent('Clicked tool in homepage', { tool_id: toolId })
}

const trackToolSearch = (): void => {
  if (searchQuery.value.trim() === '') return
  trackEvent('Searched tool in homepage', { search_query: searchQuery.value })
}
const debouncedOnSearchInput = debounce(trackToolSearch, 500)
</script>

<template>
  <section aria-labelledby="apps-section-title">
    <div class="flex justify-between items-center mb-1.5 lg:mb-3">
      <h3 id="apps-section-title" class="text-black text-opacity-70 text-base/5 font-medium ps-1.5 shrink-0">
        {{ __('My apps') }}
      </h3>
      <div v-if="!isSmallerThanTabletLandscape" class="w-full flex justify-center md:justify-end items-center">
        <div class="relative flex items-center">
          <BaseIcon name="search_outline" class="absolute start-2.5" :size="20" />
          <input
            type="text"
            v-model="searchQuery"
            :class="[
              'ps-[38px]',
              'pe-5',
              'py-2',
              'rounded-2xl',
              'ring-2',
              searchQuery ? 'bg-white' : 'bg-[#F3F3F3]',
              searchQuery ? 'ring-black' : 'ring-transparent',
              'font-serif',
              'text-xl',
              'leading-[24px]',
              'text-black',
              'placeholder:text-black',
              'outline-none',
              'transition-colors',
              'duration-250',
              'focus:bg-white',
              'focus:ring-black',
              'w-[144px]',
            ]"
            :title="__('Search apps')"
            :placeholder="__('Search apps')"
            @input="debouncedOnSearchInput"
          />
        </div>
      </div>
    </div>
    <template v-if="filteredTools.length > 0">
      <ul
        class="grid grid-cols-2 md:grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-x-6 gap-y-4 md:gap-y-10 flex-wrap"
        aria-labelledby="apps-section-title"
      >
        <li v-for="tool in filteredTools" :key="tool.id">
          <BaseButton
            class="block w-full h-full text-start truncate relative"
            :href="tool.href"
            :title="tool.name"
            @mouseenter="animateIllustrationForward(tool.id)"
            @mouseleave="animateIllustrationBackwards(tool.id)"
            @focusin="animateIllustrationForward(tool.id)"
            @focusout="animateIllustrationBackwards(tool.id)"
            @click="trackToolClick(tool.id)"
          >
            <BaseLottiePlayer
              v-if="tool.illustration"
              :aria-hidden="true"
              :ref="(e) => (toolIllustrationRefs[tool.id] = e)"
              class="rounded-3xl mb-3 justify-start aspect-square w-full overflow-hidden"
              :src="tool.illustration"
              :autoplay="false"
              :loop="false"
            />
            <div v-else class="bg-[#92FD90] w-full aspect-square rounded-3xl mb-3"></div>
            <div class="flex flex-wrap justify-between items-start gap-2">
              <span class="text-xl font-medium flex-1 text-wrap break-words text-start">{{ tool.name }}</span>
              <span class="sr-only">{{ tool.description }}</span>
              <span
                v-if="tool.isAiPowered"
                aria-hidden="true"
                class="shrink-0 bg-[#92FD90] rounded-lg p-1 w-7 text-center text-base !leading-none font-medium"
              >
                {{ p__('Short for Artificial Intelligence. Use two letters if possible.', 'AI') }}
              </span>
            </div>
          </BaseButton>
        </li>
      </ul>
    </template>
    <div v-else class="text-center pt-10">
      <p>{{ __('There are no apps matching your search.') }}</p>
    </div>
  </section>
</template>
