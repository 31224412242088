<script setup lang="ts">
import { fetchClaimPrompts, fetchGetPrompts, fetchPromptHistory, type PromptHistoryResponse } from '@/api/promptHistory'
import BaseButton from '@/components/library/BaseButton.vue'
import type { HistoryEntry } from '@/pages/api/prompt'
import { trackEvent } from '@/utils/client/analytics'
import { useSessionDataStore } from '@/utils/client/store/sessionDataStore'
import { useWindowSizeStore } from '@/utils/client/store/windowSizeStore'
import { __ } from '@/utils/shared/intl'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

enum PromptsDataStatus {
  Loading = 'Loading',
  Errored = 'Errored',
  Completed = 'Completed',
}

const { isLoggedIn, hasAnonymousUserPrompts, currentAnonymousUserPromptIds, clearAnonymousUserPromptIds } =
  useSessionDataStore()

const { isSmallerThanTabletLandscape } = useWindowSizeStore()

const limitedPrompts = ref<PromptHistoryResponse['items']>([])
const prompts = ref<PromptHistoryResponse['items']>([])
const displayedPrompts = computed<PromptHistoryResponse['items']>(() =>
  isSmallerThanTabletLandscape.value ? prompts.value.slice(0, 3) : limitedPrompts.value,
)

const tasksSectionRef = ref<HTMLElement>()

const promptsDataStatus = ref<PromptsDataStatus>(PromptsDataStatus.Loading)

const calculateLimitedPrompts = (): void => {
  const tasksContainerHeight = (tasksSectionRef.value?.getBoundingClientRect().height ?? 0) - 20
  limitedPrompts.value = prompts.value.slice(0, Math.floor(tasksContainerHeight / 35))
}

const trackViewAllClick = (): void => {
  trackEvent('Clicked "View all" in tasks section')
}

const trackTaskHistoryClick = (): void => {
  trackEvent('Clicked "Task history" in tasks section')
}

const trackPromptClick = (prompt: HistoryEntry): void => {
  trackEvent('Clicked task in tasks section', { prompt_hashid: prompt.hashid })
}

watch(prompts, calculateLimitedPrompts)

watch(
  isLoggedIn,
  async () => {
    try {
      promptsDataStatus.value = PromptsDataStatus.Loading
      if (isLoggedIn.value) {
        const promptHistoryResponse = await fetchPromptHistory()
        prompts.value = promptHistoryResponse.items
      } else {
        const promptIds = Array.from(currentAnonymousUserPromptIds.value)
        prompts.value = (await fetchGetPrompts(promptIds)).data.prompts
      }
      promptsDataStatus.value = PromptsDataStatus.Completed
    } catch {
      promptsDataStatus.value = PromptsDataStatus.Errored
    }
  },
  { immediate: true },
)

watch(
  [hasAnonymousUserPrompts, isLoggedIn],
  async () => {
    if (hasAnonymousUserPrompts.value && isLoggedIn.value) {
      try {
        const response = await fetchClaimPrompts(
          {
            hashids: Array.from(currentAnonymousUserPromptIds.value),
          },
          () => {},
        )
        // after claiming prompts, clear the anonymous user prompt ids and re-fetch the prompts
        if (response.data.hashids.length > 0) {
          clearAnonymousUserPromptIds()
          prompts.value = (await fetchGetPrompts(Array.from(currentAnonymousUserPromptIds.value))).data.prompts
        }
        promptsDataStatus.value = PromptsDataStatus.Completed
      } catch {
        promptsDataStatus.value = PromptsDataStatus.Errored
      }
    }
  },
  { immediate: true },
)

let observer: ResizeObserver | null = null

onMounted(() => {
  observer = new ResizeObserver(() => {
    calculateLimitedPrompts()
  })

  observer.observe(tasksSectionRef.value as HTMLElement)
  calculateLimitedPrompts()
})

onUnmounted(() => {
  observer?.disconnect()
})
</script>

<template>
  <section ref="tasksSectionRef" aria-labelledby="tasks-section-title">
    <div class="flex justify-between mb-2 lg:mb-[15px]">
      <h2
        id="tasks-section-title"
        class="flex-1 font-sans text-black text-base/5 lg:text-[17px]/5 font-medium lg:font-semibold truncate text-opacity-70 lg:text-opacity-100"
      >
        <BaseButton :href="'/history'" @click="trackTaskHistoryClick">{{ __('Task history') }}</BaseButton>
      </h2>
      <BaseButton
        v-if="prompts.length !== 0"
        :href="'/history'"
        class="text-sm text-black/[0.54] hover:text-black"
        @click="trackViewAllClick"
        >{{ __('View all') }}</BaseButton
      >
    </div>
    <span v-if="promptsDataStatus === PromptsDataStatus.Loading" class="text-sm">{{ __('Fetching tasks...') }}</span>
    <span v-else-if="promptsDataStatus === PromptsDataStatus.Errored" class="text-sm text-red-600">{{
      __('Failed to fetch tasks.')
    }}</span>
    <span v-else-if="prompts.length === 0" class="text-sm">{{ __('Start using tools to see your history') }}</span>
    <template v-else>
      <ol class="flex flex-col gap-3 lg:gap-[15px]" :aria-label="__('Tasks')">
        <li v-for="prompt in displayedPrompts" :key="prompt.hashid" class="truncate shrink-0 text-sm">
          <BaseButton
            class="text-black hover:text-black/[0.54]"
            :href="`/prompt/${prompt.hashid}`"
            :title="prompt.prompt_title"
            @click="trackPromptClick(prompt)"
            >{{ prompt.prompt_title }}</BaseButton
          >
        </li>
      </ol>
    </template>
  </section>
</template>
