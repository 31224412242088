// @file /api/weather.ts

import { currentEnvironment } from '@/utils/shared/environment'
import type { APIRoute } from 'astro'
import { z } from 'zod'

const OPENWEATHER_API_KEY = import.meta.env.OPENWEATHER_API_KEY

// API response schema for type safety
const openWeatherResponseSchema = z.object({
  main: z.object({
    temp: z.number(),
  }),
  weather: z.array(
    z.object({
      id: z.number(),
    }),
  ),
  sys: z.object({
    sunrise: z.number(),
    sunset: z.number(),
  }),
})

// Response schema
const weatherResponseSchema = z.object({
  temperature: z.number(),
  weatherIcon: z.string(),
})

export type WeatherResponse = z.infer<typeof weatherResponseSchema>

const WEATHER_CODES = {
  // Thunderstorm
  200: 'cloud_lightning', // thunderstorm with light rain
  201: 'cloud_lightning', // thunderstorm with rain
  202: 'cloud_lightning', // thunderstorm with heavy rain
  210: 'cloud_lightning', // light thunderstorm
  211: 'cloud_lightning', // thunderstorm
  212: 'cloud_lightning', // heavy thunderstorm
  221: 'cloud_lightning', // ragged thunderstorm
  230: 'cloud_lightning', // thunderstorm with light drizzle
  231: 'cloud_lightning', // thunderstorm with drizzle
  232: 'cloud_lightning', // thunderstorm with heavy drizzle

  // Drizzle
  300: 'light_rain', // light intensity drizzle
  301: 'light_rain', // drizzle
  302: 'rain', // heavy intensity drizzle
  310: 'light_rain', // light intensity drizzle rain
  311: 'rain', // drizzle rain
  312: 'heavy_rain', // heavy intensity drizzle rain
  313: 'heavy_rain', // shower rain and drizzle
  314: 'heavy_rain', // heavy shower rain and drizzle
  321: 'rain', // shower drizzle

  // Rain
  500: 'light_rain', // light rain
  501: 'rain', // moderate rain
  502: 'heavy_rain', // heavy intensity rain
  503: 'heavy_rain', // very heavy rain
  504: 'heavy_rain', // extreme rain
  511: 'sleet', // freezing rain
  520: 'light_rain', // light intensity shower rain
  521: 'rain', // shower rain
  522: 'heavy_rain', // heavy intensity shower rain
  531: 'heavy_rain', // ragged shower rain

  // Snow
  600: 'snow', // light snow
  601: 'snow', // snow
  602: 'snow', // heavy snow
  611: 'sleet', // sleet
  612: 'sleet', // light shower sleet
  613: 'sleet', // shower sleet
  615: 'snowy_sunny_day', // light rain and snow
  616: 'snowy_sunny_day', // rain and snow
  620: 'snow', // light shower snow
  621: 'snow', // shower snow
  622: 'snow', // heavy shower snow

  // Atmosphere
  701: 'haze', // mist
  711: 'haze', // smoke
  721: 'haze', // haze
  731: 'haze', // sand/dust whirls
  741: 'haze', // fog
  751: 'haze', // sand
  761: 'haze', // dust
  762: 'haze', // volcanic ash
  771: 'storm', // squalls
  781: 'tornado', // tornado

  // Clear and Clouds
  800: 'sun', // clear sky
  801: 'partly_cloudy', // few clouds: 11-25%
  802: 'partly_cloudy', // scattered clouds: 25-50%
  803: 'cloudy', // broken clouds: 51-84%
  804: 'cloudy', // overcast clouds: 85-100%
} as const

type WeatherCode = keyof typeof WEATHER_CODES
type WeatherIcon = (typeof WEATHER_CODES)[WeatherCode]

// Night variations of weather icons
const NIGHT_VARIATIONS = {
  haze: 'night_fog',
  wind: 'night_wind',
  rain: 'rainy_night',
  storm: 'stormy_night',
} as const

type NightVariation = (typeof NIGHT_VARIATIONS)[keyof typeof NIGHT_VARIATIONS]

// Moon phase icons
const MOON_ICONS = {
  new_moon: [0, 0.05],
  waxing_crescent: [0.05, 0.2],
  first_quarter: [0.2, 0.3],
  waxing_gibbous: [0.3, 0.45],
  full_moon: [0.45, 0.55],
  waning_gibbous: [0.55, 0.7],
  last_quarter: [0.7, 0.8],
  waning_crescent: [0.8, 0.95],
} as const

type MoonIcon = keyof typeof MOON_ICONS

type WeatherIconFinal = WeatherIcon | NightVariation | MoonIcon

function isNightTime(sunriseTimestamp: number, sunsetTimestamp: number): boolean {
  const currentTime = Date.now()
  return currentTime < sunriseTimestamp * 1000 || currentTime > sunsetTimestamp * 1000
}

function calculateMoonPhase(): number {
  const now = new Date()
  const synodic = 29.530588853 // Length of synodic month (days)
  const reference = new Date('2000-01-06T00:00:00Z') // Known new moon
  const daysSinceReference = (now.getTime() - reference.getTime()) / (1000 * 60 * 60 * 24)
  return (daysSinceReference % synodic) / synodic
}

function getMoonIcon(moonPhase: number): MoonIcon {
  // Handle the special case for new moon (0-5% or 95-100%)
  if (moonPhase > 0.95) return 'new_moon'

  for (const [icon, [min, max]] of Object.entries(MOON_ICONS)) {
    if (moonPhase <= max) {
      return icon as MoonIcon
    }
  }

  return 'new_moon' // fallback
}

function getWeatherIcon(weatherCode: WeatherCode, isNight: boolean, moonPhase: number): WeatherIconFinal {
  let iconName = WEATHER_CODES[weatherCode]

  if (!isNight) {
    return iconName
  }

  // Check if we should show moon phase instead
  const clearConditions = ['sun', 'partly_cloudy'] as const
  const stormyConditions = ['cloud_lightning', 'storm', 'tornado', 'heavy_rain'] as const
  const blockedConditions = [...stormyConditions, 'rain', 'light_rain', 'haze'] as const

  if (clearConditions.includes(iconName as any) && !blockedConditions.includes(iconName as any)) {
    return getMoonIcon(moonPhase)
  }

  // Check for night variations
  const nightIcon = NIGHT_VARIATIONS[iconName as keyof typeof NIGHT_VARIATIONS]
  return nightIcon || iconName
}

export const getWeatherIconAlt = (icon?: string): string => {
  return icon?.replace(/_/g, ' ') ?? ''
}

export const GET: APIRoute = async (context) => {
  try {
    let latitude = context.request.headers.get('cf-iplatitude')
    let longitude = context.request.headers.get('cf-iplongitude')

    if (!latitude || !longitude) {
      if (currentEnvironment === 'development') {
        // San Francisco
        latitude = '37.7749'
        longitude = '-122.4194'
      } else {
        return new Response('No latitude or longitude', { status: 400 })
      }
    }

    if (!OPENWEATHER_API_KEY) {
      return new Response('API key not configured', { status: 500 })
    }

    const response = await fetch(
      `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${OPENWEATHER_API_KEY}&units=metric`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      },
    )

    if (!response.ok) {
      console.error('Weather service error', await response.json())
      return new Response('Weather service error', { status: 502 })
    }

    const rawData = await response.json()

    // Validate API response
    const parsedApiResponse = openWeatherResponseSchema.safeParse(rawData)
    if (!parsedApiResponse.success) {
      console.error('Invalid API response:', parsedApiResponse.error)
      return new Response('Invalid API response', { status: 502 })
    }

    const data = parsedApiResponse.data
    const night = isNightTime(data.sys.sunrise, data.sys.sunset)
    const moonPhase = calculateMoonPhase()
    const weatherIcon = getWeatherIcon(data.weather[0].id as WeatherCode, night, moonPhase)

    const weatherData: WeatherResponse = {
      temperature: data.main.temp,
      weatherIcon,
    }

    // Validate the response
    const parsedResponse = weatherResponseSchema.safeParse(weatherData)
    if (!parsedResponse.success) {
      return new Response('Invalid weather data', { status: 502 })
    }

    return new Response(JSON.stringify(parsedResponse.data), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  } catch (error) {
    console.error('Weather API error:', error)
    return new Response('Internal server error', { status: 500 })
  }
}
