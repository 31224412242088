<script setup lang="ts">
import HomeAppV2Calendar from '@/components/HomeAppV2Calendar.vue'
import HomeAppV2Events from '@/components/HomeAppV2Events.vue'
import HomeAppV2Header from '@/components/HomeAppV2Header.vue'
import HomeAppV2Tasks from '@/components/HomeAppV2Tasks.vue'
import HomeAppV2Time from '@/components/HomeAppV2Time.vue'
import HomeAppV2Weather from '@/components/HomeAppV2Weather.vue'
import { useWindowSizeStore } from '@/utils/client/store/windowSizeStore'
import { __ } from '@/utils/shared/intl'

const { isSmallerThanTabletLandscape } = useWindowSizeStore()

const hideEvents = true
</script>

<template>
  <aside class="flex flex-col w-full lg:w-80 lg:h-full bg-red shrink-0 ps-4 lg:ps-5 pt-5 pe-4 lg:pe-0 lg:pb-8">
    <template v-if="isSmallerThanTabletLandscape">
      <!-- LOGO -->
      <div class="flex justify-between items-center mb-8 gap-4">
        <div class="flex gap-2 items-baseline">
          <div class="text-[32px] leading-[40px] select-none" aria-hidden="true">
            <span class="font-serif">Padlet</span>
            <span class="font-sans text-[#D900FA] font-light">TA</span>
          </div>
          <span class="text-sm font-bold font-mono uppercase">{{ __('Beta') }}</span>
        </div>
        <h1 class="sr-only">Padlet TA</h1>
        <HomeAppV2Header />
      </div>
      <!-- EVENTS -->
      <HomeAppV2Events class="mb-6" v-show="!hideEvents" />
      <!-- TASKS -->
      <HomeAppV2Tasks class="flex-1 min-h-0 ps-1.5 pe-3" />
    </template>
    <template v-else>
      <!-- LOGO -->
      <div class="flex justify-between ps-3 pe-7 mb-8">
        <div class="flex gap-2 items-baseline">
          <div class="text-[32px] leading-[40px] select-none" aria-hidden="true">
            <span class="font-serif">Padlet</span>
            <span class="font-sans text-[#D900FA] font-light">TA</span>
          </div>
          <span class="text-sm font-bold font-mono uppercase">{{ __('Beta') }}</span>
        </div>
        <h1 class="sr-only">PadletTA</h1>
      </div>
      <!-- WEATHER & LOCATION -->
      <HomeAppV2Weather class="mb-1.5 ps-4 pe-7" />
      <!-- TIME -->
      <HomeAppV2Time class="mb-6 ps-3 pe-0" />
      <!-- CALENDAR -->
      <HomeAppV2Calendar :class="[hideEvents ? 'mb-7' : 'mb-1', 'pe-7']" />
      <!-- EVENTS -->
      <HomeAppV2Events class="mb-6 ps-2.5 pe-10" v-show="!hideEvents" />
      <!-- TASKS -->
      <HomeAppV2Tasks class="flex-1 min-h-0 ps-2.5 pe-10" />
    </template>
  </aside>
</template>
