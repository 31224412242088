<script setup lang="ts">
import { getWeatherIconAlt, type WeatherResponse } from '@/pages/api/weather'
import { __ } from '@/utils/shared/intl'
import { computed, onMounted, ref } from 'vue'

declare global {
  interface Window {
    $currentCity: string
    $currentCountry: string
  }
}

enum WeatherDataStatus {
  Loading = 'Loading',
  Errored = 'Errored',
  Completed = 'Completed',
}

const weatherData = ref<WeatherResponse | null>(null)
const weatherDataStatus = ref<WeatherDataStatus>(WeatherDataStatus.Loading)

async function fetchWeather() {
  try {
    weatherDataStatus.value = WeatherDataStatus.Loading
    const response = await fetch('/api/weather')
    if (!response.ok) {
      throw new Error('Failed to fetch weather data')
    }
    weatherData.value = await response.json()
    weatherDataStatus.value = WeatherDataStatus.Completed
  } catch (e) {
    weatherDataStatus.value = WeatherDataStatus.Errored
    console.error(e)
  }
}

const currentCity = window.$currentCity || ''
const currentCountry = window.$currentCountry || ''

// @see https://worldpopulationreview.com/country-rankings/countries-that-use-fahrenheit
const prefersFahrenheit = ['US', 'LR', 'FM', 'VI', 'KY', 'MH'].includes(currentCountry)

const weatherIcon = ref()
const isUsingFahrenheit = ref(prefersFahrenheit)

const fahrenheitTemperature = computed(() => {
  if (weatherData.value?.temperature == null) return null
  return Math.round((weatherData.value?.temperature * 9) / 5 + 32)
})

const temperatureText = computed(() => {
  if (weatherData.value?.temperature == null) return null
  return isUsingFahrenheit.value
    ? `${fahrenheitTemperature.value}°F`
    : `${Math.round(weatherData.value?.temperature)}°C`
})

onMounted(async () => {
  await fetchWeather()
  const icons = import.meta.glob('/src/assets/icons/SVG/weather_*.svg', { import: 'default' })
  weatherIcon.value = await icons[`/src/assets/icons/SVG/weather_${weatherData.value?.weatherIcon}.svg`]()
})
</script>

<template>
  <section
    class="flex items-center gap-1 font-semibold text-[17px] leading-[22px] font-sans"
    :aria-label="__('Weather')"
  >
    <span v-if="weatherDataStatus === WeatherDataStatus.Loading" class="truncate">{{ __('Loading weather...') }}</span>
    <span v-else-if="weatherDataStatus === WeatherDataStatus.Errored" class="truncate text-red-600">{{
      __('Failed to load weather data.')
    }}</span>
    <template v-else>
      <span @click="isUsingFahrenheit = !isUsingFahrenheit">{{ temperatureText }}</span>
      <img
        v-if="weatherIcon"
        class="h-5 w-5"
        :src="weatherIcon.src"
        :alt="getWeatherIconAlt(weatherData?.weatherIcon)"
      />
    </template>
    <span class="truncate" :title="currentCity">{{ currentCity }}</span>
  </section>
</template>
