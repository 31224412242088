<script setup lang="ts">
import { getSessionData } from '@/api/auth'
import HomeAppV2Header from '@/components/HomeAppV2Header.vue'
import HomeAppV2Main from '@/components/HomeAppV2Main.vue'
import HomeAppV2Sidebar from '@/components/HomeAppV2Sidebar.vue'
import { initializeLogin } from '@/utils/client/auth/loginPopup'
import { useSessionDataStore } from '@/utils/client/store/sessionDataStore'
import { useWindowSizeStore } from '@/utils/client/store/windowSizeStore'
import type { AccessStatus } from '@/utils/server/dbAccess/types'
import { onBeforeMount, onMounted } from 'vue'

defineProps<{
  initialToolAccess: Record<string, AccessStatus>
}>()

const { isSmallerThanTabletLandscape } = useWindowSizeStore()
const { setSessionUser } = useSessionDataStore()

onBeforeMount(async () => {
  const url = new URL(window.location.href)
  const loggedIn = url.searchParams.get('logged_in') === 'true'

  if (loggedIn) {
    // If the user is logged in, we need to initialize the login process on the client side
    await initializeLogin(async () => {
      const sessionData = await getSessionData()
      if (sessionData.user) {
        setSessionUser(sessionData.user)
      }
      // Remove the logged_in parameter from the URL once the login process is complete
      window.history.pushState({}, '', '/')
    })
  }
})

onMounted(async () => {
  const sessionData = await getSessionData()
  if (sessionData.user) {
    setSessionUser(sessionData.user)
  } else {
    setSessionUser(null)
  }
})
</script>

<template>
  <div class="h-full w-full flex flex-col lg:flex-row">
    <HomeAppV2Sidebar />
    <div class="min-w-0 w-full h-full flex flex-col">
      <HomeAppV2Header v-if="!isSmallerThanTabletLandscape" />
      <HomeAppV2Main :initial-tool-access="initialToolAccess" />
    </div>
  </div>
</template>
