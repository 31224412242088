<script setup lang="ts">
import BaseButton from '@/components/library/BaseButton.vue'
import BaseIcon from '@/components/library/BaseIcon.vue'

defineProps<{
  text: string
  icon: string
  href?: string
}>()
</script>

<template>
  <BaseButton
    :class="[
      // Layout
      'flex',
      'items-center',
      'text-start',
      'gap-2',
      'py-1.5',
      'ps-3',
      'pe-4',
      'rounded-2xl',
      'min-w-0',

      // Base
      'outline-none',
      'text-black',
      'text-opacity-85',

      // Hover
      'hover:bg-[#F3F3F3]',
      // Active
      'active:text-[#D900FA]',
      'active:bg-[#F3F3F3]',
      // Focus
      'focus-visible:ring-2',
      'focus-visible:ring-offset-2',
      'focus-visible:ring-[#D900FA]',
    ]"
    :href="href ?? undefined"
    :target="href ? '_blank' : undefined"
    :title="text"
  >
    <BaseIcon :name="icon" :size="20" />
    <span class="text-xl font-medium truncate">{{ text }}</span>
  </BaseButton>
</template>
