<script setup>
import { __ } from '@/utils/shared/intl'
import { getCurrentLocale } from '@/utils/shared/locale'
import { computed, onMounted, ref } from 'vue'

const time = ref(new Date())
onMounted(() => {
  setInterval(() => {
    time.value = new Date()
  }, 1000)
})

const localeTimeString = computed(() => {
  const currentLocale = getCurrentLocale()
  return time.value.toLocaleTimeString(currentLocale, { hour: '2-digit', minute: '2-digit' }).replace(/^0/, '')
})

const hoursAndMinutesDelimiter = computed(() => {
  return localeTimeString.value.includes(':')
    ? ':'
    : localeTimeString.value.includes('.')
      ? '.' // e.g. in de-DE (Dansk)
      : localeTimeString.value.includes(',')
        ? '-' // e.g. in mr_IN. We're not supporting Marathi, but I'm just adding this here for completeness
        : null // If it's some other delimiter, this is null and we'll display the localeTimeString as is without blinking the delimiter
})

const localeTimeStringComponents = computed(() => {
  if (hoursAndMinutesDelimiter.value) {
    return localeTimeString.value.split(hoursAndMinutesDelimiter.value)
  }
  return null
})
</script>

<template>
  <section :aria-label="__('Time')">
    <p class="text-black text-[32px] md:text-[48px] lg:text-[60px] leading-none font-sans font-semibold">
      <template v-if="localeTimeStringComponents">
        {{ localeTimeStringComponents[0]
        }}<span :class="time.getSeconds() % 2 === 0 ? 'opacity-0' : ''">{{ hoursAndMinutesDelimiter }}</span
        >{{ localeTimeStringComponents[1] }}
      </template>
      <template v-else>
        {{ localeTimeString }}
      </template>
    </p>
  </section>
</template>
