<script setup lang="ts">
import { __ } from '@/utils/shared/intl'
import { getCurrentLocale } from '@/utils/shared/locale'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

let intervalId: number
const today = ref<Date>(new Date())
onMounted(() => {
  intervalId = window.setInterval(() => {
    today.value = new Date()
  }, 60000)
})

onBeforeUnmount(() => {
  window.clearInterval(intervalId)
})

const month = computed<string>(() => today.value.toLocaleString(getCurrentLocale(), { month: 'long' }))
const firstDayOfWeek = new Intl.Locale(getCurrentLocale()).getWeekInfo?.()?.firstDay ?? 7 // Firefox doesn't support getWeekInfo
const todayDay = computed<number>(() => today.value.getDay())

const daysRange = computed<number[]>(() => {
  const normalizedTodayDay = todayDay.value === 0 ? 7 : todayDay.value
  let difference
  if (normalizedTodayDay <= firstDayOfWeek) {
    difference = -(normalizedTodayDay % firstDayOfWeek)
  } else {
    difference = firstDayOfWeek - normalizedTodayDay
  }
  return [difference, 14 + difference]
})

const daysToDisplay = computed<Date[]>(() => {
  const days = []
  for (let dayIndex = daysRange.value[0]; dayIndex <= daysRange.value[1]; dayIndex++) {
    const day = new Date()
    day.setDate(day.getDate() + dayIndex)
    days.push(day)
  }
  return days
})

const getNarrowDayNameFromDate = (date: Date): string => {
  return date.toLocaleString(getCurrentLocale(), { weekday: 'narrow' })
}

const getDayNameFromDate = (date: Date): string => {
  return date.toLocaleString(getCurrentLocale(), { weekday: 'long' })
}

const getHumanReadableDate = (date: Date): string => {
  return date.toLocaleString(getCurrentLocale(), {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}
</script>

<template>
  <section :aria-label="__('Calendar')">
    <h2
      id="calendar-section-title"
      class="flex-1 ps-3 mb-1 font-sans text-[17px] leading-5 font-semibold truncate capitalize"
    >
      {{ month }}
    </h2>
    <table class="flex flex-col gap-y-[1px] text-[10px] text-[#333333]">
      <thead>
        <tr class="grid grid-cols-[repeat(7,min-content)] lg:grid-cols-7">
          <th
            v-for="day in daysToDisplay.slice(0, 7)"
            class="flex justify-center items-center box-content w-[22px] lg:w-auto h-[22px] px-1.5 py-1 font-semibold capitalize"
            :title="getDayNameFromDate(day)"
            :aria-label="getDayNameFromDate(day)"
            scope="col"
          >
            {{ getNarrowDayNameFromDate(day) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="grid grid-cols-[repeat(7,min-content)] lg:grid-cols-7">
          <td
            v-for="day in daysToDisplay.slice(0, 7)"
            class="flex justify-center box-content px-1.5 py-1 w-auto"
            :title="getHumanReadableDate(day)"
            :aria-label="getHumanReadableDate(day)"
          >
            <div
              :class="[
                'h-[22px]',
                'w-[22px]',
                'flex',
                'justify-center',
                'items-center',
                'rounded-full',
                day.toDateString() === today.toDateString() && ['font-semibold', 'bg-[#F8C7FF]'],
              ]"
            >
              <span class="mt-[1px]">{{ day.getDate() }}</span>
            </div>
          </td>
        </tr>
        <tr class="grid grid-cols-[repeat(7,min-content)] lg:grid-cols-7">
          <td
            v-for="day in daysToDisplay.slice(7, 14)"
            class="flex justify-center box-content px-1.5 py-1 w-auto"
            :title="getHumanReadableDate(day)"
            :aria-label="getHumanReadableDate(day)"
          >
            <div
              :class="[
                'h-[22px]',
                'w-[22px]',
                'flex',
                'justify-center',
                'items-center',
                'rounded-full',
                day.toDateString() === today.toDateString() && ['font-semibold', 'bg-[#F8C7FF]'],
              ]"
            >
              <span class="mt-[1px]">{{ day.getDate() }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>
