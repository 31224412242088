<script setup lang="ts">
import { getSessionData } from '@/api/auth'
import AuthButton from '@/components/AuthButton.vue'
import HomeAppV2HeaderButton from '@/components/HomeAppV2HeaderButton.vue'
import BaseButton from '@/components/library/BaseButton.vue'
import BaseIcon from '@/components/library/BaseIcon.vue'
import { initializeLogin, Method } from '@/utils/client/auth/loginPopup'
import { useSessionDataStore } from '@/utils/client/store/sessionDataStore'
import { useWindowSizeStore } from '@/utils/client/store/windowSizeStore'
import { isProduction } from '@/utils/shared/environment'
import { __ } from '@/utils/shared/intl'
import { getCurrentLocale } from '@/utils/shared/locale'
import { onMounted, onUnmounted } from 'vue'

const { isSmallerThanTabletLandscape } = useWindowSizeStore()

let removeMessageListener: (() => void) | null = null

const reloadPage = (): void => {
  window.location.reload()
}

const handleLogin = async (): Promise<void> => {
  removeMessageListener = await initializeLogin(reloadPage)
}

const handleSignup = async (): Promise<void> => {
  removeMessageListener = await initializeLogin(reloadPage, Method.Signup)
}

const helpUrl = (() => {
  const url = new URL(isProduction ? 'https://padlet.com/contact-us' : 'https://padlet.dev/contact-us')
  url.searchParams.set('message', 'Hello, I need help with Padlet TA.')
  url.searchParams.set('selected', 'Others')
  return url.toString()
})()

const { isLoaded, user, setSessionUser } = useSessionDataStore()

onMounted(async () => {
  const sessionData = await getSessionData()
  if (sessionData.user) {
    setSessionUser(sessionData.user)
  } else {
    setSessionUser(null)
  }
})

onUnmounted((): void => {
  if (removeMessageListener) removeMessageListener()
})
</script>

<template>
  <header class="flex items-center justify-end w-full lg:px-6 lg:py-[19px] gap-6 lg:gap-4 overflow-hidden">
    <template v-if="!isSmallerThanTabletLandscape">
      <HomeAppV2HeaderButton :text="__('Help')" :icon="'interface_help'" :href="helpUrl" />
      <template v-if="isLoaded">
        <AuthButton v-if="user" :loaded-locale="getCurrentLocale()" />
        <template v-else>
          <HomeAppV2HeaderButton :text="__('Log in')" :icon="'login_outline'" @click="handleLogin" />
          <HomeAppV2HeaderButton :text="__('Sign up')" :icon="'signup_outline'" @click="handleSignup" />
        </template>
      </template>
    </template>
    <template v-else>
      <template v-if="isLoaded">
        <template v-if="user">
          <BaseButton class="flex items-center gap-2 text-base font-medium min-w-0" :href="helpUrl">
            <BaseIcon :name="'interface_help'" :size="20" />
            <span class="text-black/85 hover:text-black/55 truncate">
              {{ __('Help') }}
            </span>
          </BaseButton>
          <AuthButton :loaded-locale="getCurrentLocale()" />
        </template>
        <template v-else>
          <BaseButton class="flex items-center gap-2 text-base font-medium min-w-0" @click="handleLogin">
            <BaseIcon class="shrink-0" :name="'login_outline'" :size="20" />
            <span class="text-black/85 hover:text-black/55 truncate">
              {{ __('Log in') }}
            </span>
          </BaseButton>
          <BaseButton class="flex items-center gap-2 text-base font-medium min-w-0" @click="handleSignup">
            <BaseIcon class="shrink-0" :name="'signup_outline'" :size="20" />
            <span class="text-black/85 hover:text-black/55 truncate">
              {{ __('Sign up') }}
            </span>
          </BaseButton>
        </template>
      </template>
    </template>
  </header>
</template>
