<script setup lang="ts">
import BaseButton from '@/components//library/BaseButton.vue'
import { __ } from '@/utils/shared/intl'
import { getCurrentLocale } from '@/utils/shared/locale'
import { computed } from 'vue'

type Event = any

const now = new Date()
const pilatesEvent = new Date()
pilatesEvent.setHours(now.getHours() + 1)
const englishEvent = new Date()
englishEvent.setHours(now.getHours() + 2)
const EVENTS = [
  {
    name: 'Pilates',
    dateTime: pilatesEvent,
    eventUrl: 'https://padlet.com',
    location:
      'Petronas Twin Tower, Lower Ground (Concourse) Level, Kuala Lumpur City Centre, 50088 Kuala Lumpur, Malaysia',
  },
  {
    name: 'English',
    dateTime: englishEvent,
    eventUrl: 'https://padlet.com',
    meetingUrl: 'https://padlet.com',
  },
  {
    name: 'birthday',
    dateTime: now,
    eventUrl: 'https://padlet.com',
    allDay: true,
  },
  {
    name: 'holiday',
    dateTime: now,
    eventUrl: 'https://padlet.com',
    allDay: true,
  },
  {
    name: 'maldives trip with friends',
    dateTime: now,
    eventUrl: 'https://padlet.com',
    allDay: true,
  },
]

const formattedEvents = computed<Event[]>((): Event[] => {
  const allDayEventNames: string[] = []
  const eventsToDisplay: Event[] = []
  EVENTS.forEach((event: Event): void => {
    if (event.allDay) {
      allDayEventNames.push(event.name)
      return
    }
    eventsToDisplay.push(event)
  })

  const allDayEventName = allDayEventNames.join(', ')
  if (allDayEventName.length > 0) {
    eventsToDisplay.unshift({
      name: allDayEventName,
      allDay: true,
    })
  }
  return eventsToDisplay.slice(0, 4)
})

const getLocalizedTime = (date: Date): string => {
  return date
    .toLocaleTimeString(getCurrentLocale(), { hour: '2-digit', minute: '2-digit' })
    .replace(/^0/, '')
    .replace(/\s/g, '')
}

const getGoogleMapsDirections = (location: string): string => {
  return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(location)}`
}
</script>

<template>
  <section :aria-label="__('Events')">
    <ol class="flex flex-col gap-0.5 text-gray-900">
      <li v-for="event in formattedEvents" class="flex justify-between items-center gap-1.5 py-1">
        <BaseButton class="truncate text-sm" :href="event.eventUrl" target="_blank">
          <span :title="event.name">{{ event.name }}</span>
        </BaseButton>
        <div class="flex items-center gap-1.5 shrink-0 text-xs">
          <template v-if="event.meetingUrl">
            <BaseButton class="flex items-center" :href="event.meetingUrl" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M3.50065 3C2.31 3 1.33398 3.97602 1.33398 5.16667V10.8333C1.33398 12.024 2.31 13 3.50065 13H9.16732C10.358 13 11.334 12.024 11.334 10.8333V10.3835L13.9102 11.929C13.986 11.9745 14.0727 11.9991 14.1611 12.0002C14.2496 12.0012 14.3368 11.9788 14.4138 11.9352C14.4908 11.8916 14.5548 11.8283 14.5993 11.7519C14.6439 11.6754 14.6673 11.5885 14.6673 11.5V4.5C14.6673 4.43306 14.6539 4.3668 14.6279 4.30514C14.6018 4.24349 14.5636 4.18769 14.5156 4.14106C14.4676 4.09443 14.4107 4.05792 14.3483 4.03369C14.2859 4.00946 14.2193 3.998 14.1523 4C14.0669 4.00256 13.9835 4.02699 13.9102 4.07096L11.334 5.61654V5.16667C11.334 3.97602 10.358 3 9.16732 3H3.50065ZM3.50065 4H9.16732C9.81734 4 10.334 4.51665 10.334 5.16667V6.48438V9.49414V10.8333C10.334 11.4834 9.81734 12 9.16732 12H3.50065C2.85063 12 2.33398 11.4834 2.33398 10.8333V5.16667C2.33398 4.51665 2.85063 4 3.50065 4ZM13.6673 5.38346V10.6165L11.334 9.2168V6.7832L13.6673 5.38346Z"
                  fill="black"
                />
              </svg>
            </BaseButton>
          </template>
          <template v-if="event.location">
            <BaseButton class="flex items-center" :href="getGoogleMapsDirections(event.location)" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M8.00065 1.33301C4.87707 1.33301 2.33398 3.87609 2.33398 6.99967C2.33398 8.40194 2.84842 9.68795 3.69336 10.6768L3.69596 10.6794L3.69792 10.682C3.69792 10.682 6.10856 13.4327 6.9668 14.2516C7.54151 14.7996 8.45914 14.7996 9.03385 14.2516C10.0121 13.3186 12.304 10.6807 12.304 10.6807L12.3053 10.6787L12.3073 10.6768C13.153 9.68794 13.6673 8.40194 13.6673 6.99967C13.6673 3.87609 11.1242 1.33301 8.00065 1.33301ZM8.00065 2.33301C10.5837 2.33301 12.6673 4.41659 12.6673 6.99967C12.6673 8.15808 12.2452 9.21054 11.5469 10.027C11.5423 10.0322 9.19733 12.7142 8.34375 13.5283C8.14579 13.7171 7.85486 13.7171 7.6569 13.5283C6.94351 12.8476 4.46102 10.0352 4.45378 10.027L4.45312 10.0264C3.75576 9.20995 3.33398 8.15776 3.33398 6.99967C3.33398 4.41659 5.41757 2.33301 8.00065 2.33301ZM8.00065 4.99967C7.37565 4.99967 6.84359 5.25205 6.50195 5.63639C6.16032 6.02073 6.00065 6.51356 6.00065 6.99967C6.00065 7.48579 6.16032 7.97861 6.50195 8.36296C6.84359 8.7473 7.37565 8.99967 8.00065 8.99967C8.62565 8.99967 9.15771 8.7473 9.49935 8.36296C9.84098 7.97861 10.0007 7.48579 10.0007 6.99967C10.0007 6.51356 9.84098 6.02073 9.49935 5.63639C9.15771 5.25205 8.62565 4.99967 8.00065 4.99967ZM8.00065 5.99967C8.37565 5.99967 8.59359 6.1223 8.75195 6.30046C8.91032 6.47861 9.00065 6.73579 9.00065 6.99967C9.00065 7.26356 8.91032 7.52073 8.75195 7.69889C8.59359 7.87705 8.37565 7.99967 8.00065 7.99967C7.62565 7.99967 7.40771 7.87705 7.24935 7.69889C7.09098 7.52073 7.00065 7.26356 7.00065 6.99967C7.00065 6.73579 7.09098 6.47861 7.24935 6.30046C7.40771 6.1223 7.62565 5.99967 8.00065 5.99967Z"
                  fill="black"
                />
              </svg>
            </BaseButton>
          </template>
          <span class="w-[60px] text-end">{{ event.dateTime ? getLocalizedTime(event.dateTime) : __('All day') }}</span>
        </div>
      </li>
    </ol>
  </section>
</template>
