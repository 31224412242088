<script setup lang="ts">
import { DotLottieWorker } from '@lottiefiles/dotlottie-web'
import { onMounted, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    src?: string
    backgroundColor?: string
    speed?: number
    autoplay?: boolean
    loop?: boolean
    shouldFadeIn?: boolean
    shouldFadeOut?: boolean
    wrapperStyle?: Record<string, string>
  }>(),
  {
    src: 'https://padlet.net/lottie/vinyl-loading-dark.json',
    backgroundColor: 'transparent',
    speed: 1,
    autoplay: true,
    loop: true,
    shouldFadeIn: true,
    shouldFadeOut: true,
    wrapperStyle: undefined,
  },
)

const lottieWorkerInstance = ref<DotLottieWorker>()

onMounted(() => {
  if (dotlottieCanvasRef.value == null) return
  // eslint-disable-next-line no-new
  lottieWorkerInstance.value = new DotLottieWorker({
    canvas: dotlottieCanvasRef.value,
    src: props.src,
    backgroundColor: props.backgroundColor,
    speed: props.speed,
    autoplay: props.autoplay,
    loop: props.loop,
    renderConfig: {
      freezeOnOffscreen: false,
    },
  })
})

const dotlottieCanvasRef = ref<HTMLCanvasElement>()

defineExpose({
  lottieWorkerInstance,
})
</script>

<template>
  <Transition
    :duration="{ leave: shouldFadeOut ? 200 : 0, enter: 0 }"
    leave-active-class="duration-200 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="transform opacity-0"
  >
    <div :style="wrapperStyle">
      <canvas ref="dotlottieCanvasRef" :class="[shouldFadeIn && 'animate-fade-in', 'w-full h-full']" />
    </div>
  </Transition>
</template>
