<script setup lang="ts">
import HomeAppV2MainApps from '@/components/HomeAppV2MainApps.vue'
import HomeAppV2MainSuggestions from '@/components/HomeAppV2MainSuggestions.vue'
import { useTools } from '@/utils/client/vuecomposables/useTools'
import type { AccessStatus } from '@/utils/server/dbAccess/types'
import { computed } from 'vue'

const props = defineProps<{
  initialToolAccess: Record<string, AccessStatus>
}>()

const { tools: allTools } = useTools(props.initialToolAccess)

const tools = computed(() => {
  // Filter tools based on access from props
  return allTools.value.map((tool) => ({
    ...tool,
    name:
      props.initialToolAccess[tool.id] === 'beta'
        ? `${tool.name} (Beta)`
        : props.initialToolAccess[tool.id] === 'internal'
          ? `${tool.name} (Internal)`
          : tool.name,
  }))
})

const enableTaskSuggestions = false
</script>

<template>
  <main
    id="main"
    :class="[
      'ps-4',
      'lg:ps-[112px]',
      'pt-[34px]',
      enableTaskSuggestions ? 'lg:pt-[56px]' : 'lg:pt-[14px]',
      'pe-4',
      'lg:pe-10',
      'pb-8',
      'flex-1',
      'lg:overflow-auto',
      'min-w-0',
    ]"
  >
    <!-- SUGGESTIONS -->
    <HomeAppV2MainSuggestions v-if="enableTaskSuggestions" class="mb-6 lg:mb-10" />
    <!-- APPS -->
    <HomeAppV2MainApps :tools="tools" />
  </main>
</template>
