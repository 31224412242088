<script setup lang="ts">
import BaseButton from '@/components/library/BaseButton.vue'
import BaseIcon from '@/components/library/BaseIcon.vue'
import { getToolFromId } from '@/utils/client/tools'
import { __ } from '@/utils/shared/intl'

interface TaskSuggestion {
  id: string
  toolId: string
  taskDescription: string
}

const taskSuggestions = [
  {
    id: '1',
    toolId: 'worksheet',
    taskDescription: 'Balancing chemical equations for accurate representations',
  },
  {
    id: '2',
    toolId: 'multimedia-presentation',
    taskDescription: 'Redox reactions involving electron transfer',
  },
  {
    id: '3',
    toolId: 'class-activity-ideas',
    taskDescription: 'Acid-base titration techniques and calculations',
  },
]

const getToolNameFromId = (toolId: string): string => {
  const tool = getToolFromId(toolId)
  return tool?.name || ''
}

const getTaskSuggestionUrl = (taskSuggestion: TaskSuggestion): string => {
  return '#'
}

const refreshTaskSuggestions = (): void => {
  // TODO
}
</script>

<template>
  <section :aria-label="__('Task suggestions')">
    <div class="flex justify-between items-center mb-2.5 lg:mb-5 ps-1.5">
      <h3 class="text-black text-opacity-70 text-base/5 font-medium">{{ __('Suggestions for you') }}</h3>
      <BaseButton class="text-black/70" @click="refreshTaskSuggestions">
        <BaseIcon class="!block" name="interface_reboot" :size="20" />
      </BaseButton>
    </div>
    <ul class="flex gap-2 overflow-auto" :aria-label="__('Suggestions')">
      <li
        v-for="taskSuggestion in taskSuggestions"
        :key="taskSuggestion.id"
        class="h-[150px] min-w-[min(calc(100%-32px),332px)] w-full"
      >
        <BaseButton
          class="flex flex-col items-start text-start gap-1.5 p-3 rounded-2xl bg-[#F6FF93] hover:bg-[#F6FF93]/60 w-full h-full"
          :aria-label="__('')"
          :href="getTaskSuggestionUrl(taskSuggestion)"
        >
          <span class="px-2.5 py-1 text-xs text-gray-900 bg-[#92FD90] font-medium rounded-md max-w-full truncate">
            {{ getToolNameFromId(taskSuggestion.toolId) }}
          </span>
          <span class="ps-0.5 font-serif text-[28px] !leading-[32px] text-gray-900 line-clamp-2">
            {{ taskSuggestion.taskDescription }}
          </span>
        </BaseButton>
      </li>
    </ul>
  </section>
</template>
